// Gradients



// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
  background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
  background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

body {
	background-color: $gl-offwhite;
}

.full-width {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: initial;
}

.inline-list {
	margin-bottom: 0;
	margin-left: 0;
	list-style: none;
	li {
		display: inline;
	}
}

img,
picture {
	vertical-align: top;
}

.jumbotron {
	position: relative;
	vertical-align: top;
	margin-bottom: 1rem;
	h1 {
		line-height: 3rem;
	}
	.overlay {
		background-color: $gl-dkblue;
		vertical-align: top;
		color: #fff;
		padding: 1rem 2rem;
		text-shadow: 0 1px 1px darken($gl-dkblue, 20%);
		@include breakpoint(medium) {
			background-color: fade-out(darken($gl-dkblue, 20%), .2);
			position: absolute;
			width: 100%;
			bottom: 0;
		}
		@include breakpoint(large) {
			top: 20%;
			width: 60%;
			right: 0;
			bottom: auto;
		}
	}
}

.button {
	font-family: $font-family-sans-serif;
	&.hollow.invert {
		border: 1px solid #fff;
		color: #fff;
	}
}

.resource-list {
	@extend .no-bullet;
	.resource-title {
		@extend h5;
	}
}

.site-header {
	background-color: $white;
	padding: 2rem 0;
	border-top: solid 8px $gl-mdblue;
	.logo {
		max-width: 100%;
	}
	.contact-methods {
		color: $gl-brown;
		font-size: $small-font-size;
		margin-bottom: .5rem;
		@extend .medium-text-right;
		> li > * {
			display: inline;
		}
		a {
			color: $gl-mdblue;
		}
	} 
}

.global-nav {
	@extend .medium-text-right;
	text-transform: uppercase;
	font-family: $body-font-family;
	font-weight: 600;
	font-size: rem-calc(14);
	letter-spacing: .12rem;
	a {
		color: $gl-dkblue;
	}
	li {
		position: relative;
		display: inline-block;
		margin-right: 1.3rem;
		&::after {
			content: '/';
			position: absolute;
			top: -.5rem;
			right: -1.3rem;
			color: $gl-brown;
			font-weight: 900;
			font-size: $global-font-size * 1.5;
			// font-size: rem-calc(24);
		}
		&:last-child {
			margin-right: 0;
			&::after {
				content: none;
			}
		}
	}
}

.service-container {
	
}

.service-preview {
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
	.service-name {
		@extend h3;
		font-weight: 700;
		line-height: 2.5rem;
		a {
			color: $gl-mdblue;
			display: block;
			&:hover,
			&:focus {
				color: $gl-dkblue;
			}
		}
	}
	p {
		font-size: rem-calc(12);
	}
}

.specialization-preview {
	@extend .no-bullet;
	li {
		padding: .5rem 0;
		border-bottom: solid 1px $gl-offwhite;
		&:last-child {
			border-bottom: none;
		}
	}
	a {
		color: lighten($gl-brown, 10%);
		&:hover {
			color: $gl-brown;
		}
	}
	.injected-svg {
		path,
		polygon {
			fill: darken($gl-offwhite, 5%);
		}
	}
}

.specialization-title {
	@extend h5;
	margin-bottom: 1rem;
	a {
		color: $gl-brown;
	}
	.injected-svg {
		path,
		polygon {
			fill: darken($gl-offwhite, 5%);
		}
	}
}

.specialization-summary + .button {
	margin-top: 1rem;
}

.site-footer {
	// padding-top: 2rem;
	// padding-bottom: 2rem;
}

.footer-highlight {
	font-family: $body-font-family;
	font-weight: 800;
	color: $gl-red;
	font-size: rem-calc(36);
}

.footer-message {
	background-color: $gl-brown;
	.backgroundblendmode & {
		background-image: url(../img/textured_paper.png);
		background-blend-mode: multiply;
	}
	padding-top: 2rem;
	padding-bottom: 2rem;
	color: $white;
	text-shadow: 0 1px 1px darken($gl-brown, 15%);
	h3 {
		color: $gl-ltblue;
	}
	a {
      color: $gl-ltblue;
      // text-decoration: underline;

      img { border:none; }
    }
    .contact-methods {
    	color: $gl-offwhite;
    	font-size: rem-calc(14);
    	margin-bottom: 0;
    }
}

.footer-siteinfo {
	padding-top: 2rem;
	padding-bottom: 2rem;
	p {
		font-size: rem-calc(12);
	}
}

.svg-inject {
	width: 100%;
	height: auto;
	visibility: hidden;
	&.injected-svg {
		visibility: visible;
	}
}

#title-icon {
	width: 75px;
	height: 75px;
	path {
		fill: $gl-dkblue;
	}
}

#main-content {
	border-top: solid 1px $gl-offwhite;
	background-color: lighten($gl-offwhite, 5%);
	padding-top: 2rem;
	padding-bottom: 2rem;
	// h1, h2, h3, h4, h5, h6 {
	// 	text-shadow: 0 1px 0 rgba(255,255,255,.9);
	// }
}

.page-header {
	h1 {
		// font-size: rem-calc(60);
		color: $gl-red;
		text-shadow: 0 1px rgba(255,255,255,.9);
		font-weight: 900;
	}
	.injected-svg {
		path,
		polygon {
			fill: lighten($gl-red, 20%);
		}
	}
}

.row .profile-preview {
	margin-bottom: -2rem;
}

.profile-preview-item {
	margin-bottom: 2rem;
	.profile-wrapper {
		position: relative;
		display: block;
	}
	.profile-caption {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 2rem 1rem 1rem;
		@include gradient-vertical(transparent, $gl-brown);
		text-shadow: 0 1px 2px fade-out(darken($gl-brown, 15%), .1);
	}
	.profile-name {
		font-family: $body-font-family;
		font-size: rem-calc(18);
		font-weight: 800;
		color: $white;
	}
	.profile-job-title {
		font-size: rem-calc(14);
		color: $gl-offwhite;
		font-style: italic;
	}
	img {
		vertical-align: bottom;
	}
}

.job-title {
	font-size: rem-calc(24);
	font-style: italic;
	font-weight: 100;
	color: $gl-brown;
}
.pdf-container {
	position: relative;
	padding-bottom: 117.647059%; /* 8.5:11 */
	padding-top: 1rem;
	margin-bottom: 1rem;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.profile-portrait {
	@extend .thumbnail;
	&:hover,
	&:focus {
		// box-shadow: $thumb-box-shadow;
	}
}